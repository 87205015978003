@import "./variables.scss";

.App {
}

.category-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.category-image.active {
    border: 1px solid #000;
}
